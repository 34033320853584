export default async function getMenuData() {
    return [
        {
            key: 'WITH_DASHBOARD',
            menu: [
                {
                    title: 'Home',
                    key: 'home',
                    icon: 'fa fa-home',
                    children: [
                        {
                            title: 'Performance',
                            key: 'gam-reports',
                            icon: '',
                            // roles: ['admin'], // set user roles with access to this route
                            url: '/gam/reports',
                        },
                        {
                            title: 'Ad Unit',
                            key: 'adUnit-reports',
                            icon: '',
                            // roles: ['admin'], // set user roles with access to this route
                            url: '/gam/reports/adunits',
                        },
                        {
                            title: 'Geography',
                            key: 'geo-reports',
                            icon: '',
                            // roles: ['admin'], // set user roles with access to this route
                            url: '/gam/reports/geo',
                        },
                        {
                            title: 'Device Type',
                            key: 'device-reports',
                            icon: '',
                            // roles: ['admin'], // set user roles with access to this route
                            url: '/gam/reports/devices',
                        },
                    ],
                },
            ],
        },
        {
            key: 'WITHOUT_DASHBOARD',
            menu: [
                {
                    category: true,
                    title: 'Home',
                },
                {
                    title: 'Dashboard',
                    key: 'dashboards',
                    icon: 'fe fe-pie-chart',
                    // roles: ['admin'], // set user roles with access to this route
                    url: '/dashboard/',
                },
            ],
        },
        {
            title: 'Websites',
            key: 'websites',
            icon: 'fe fe-list',
            url: '/websites',
        },
        {
            title: 'Billing Summary',
            key: 'billing-summary',
            icon: 'fa fa-money',
            url: '/billing-summary',
        },
        {
            title: 'Manage GAM Accounts',
            key: 'manage-gam-accounts',
            icon: 'fa fa-cog',
            url: '/gam/account-management',
        },

        // {
        //   title: 'Prebid Reports',
        //   key: 'prebid-reports',
        //   icon: 'fe fe-home',
        //   // roles: ['admin'], // set user roles with access to this route
        //   url: '/prebid/reports/',
        // },

        // {
        //   category: true,
        //   title: 'Settings',
        // },

        //  {
        //   title: 'GAM Settings',
        //   key: 'gam-settings',
        //   icon: 'fe fe-settings',
        //   // roles: ['admin'], // set user roles with access to this route
        //   url: '/gam/settings',
        // },
        // {
        //   title: 'Profile',
        //   key: 'appsProfile',
        //   url: '/profile',
        //   icon: 'fe fe-user',
        // },
    ];
}
